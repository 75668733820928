import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import { CurrencyRate, CurrencyRates } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<CurrencyRate, CurrencyRates>
>;

@Component
export default class CurrencyRatesMixin extends Mixins(TypedModelMixin) {
  obModelClass = CurrencyRate;
  obCollection = new CurrencyRates();
  obCollectionClass = CurrencyRates;
  sRouteName = "currencyrates.list";

  created() {
    this.onCreated();
  }
}
